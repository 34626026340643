import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { InteractionRequiredAuthError, AuthError } from 'msal';
import { ApiService } from '../api.service';
import { Reporte } from '../entity/reporte';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
import sql from 'mssql';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile: any;
  lista: any;
  url: SafeResourceUrl;
  headers: any;

  constructor(
    private authService: MsalService, 
    private http: HttpClient,
    private apiService: ApiService,
    public sanitizer:DomSanitizer) { }

  ngOnInit() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*');

    this.getProfile();
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
    .subscribe({
      next: (profile) => {
        this.profile = profile;
        console.log(profile);
        this.apiService.getRep(this.profile.userPrincipalName).subscribe(data=>{
          console.log(data);
          this.lista=data;
        });
      },
      error: (err: AuthError) => {
        // If there is an interaction required error,
        // call one of the interactive methods and then make the request again.
        if (InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)) {
          this.authService.acquireTokenPopup({
            scopes: this.authService.getScopesForEndpoint(GRAPH_ENDPOINT)
          }).catch(function (error) {
            console.log(error);
          })
          .then(() => {
            this.http.get(GRAPH_ENDPOINT)
              .toPromise()
              .then(profile => {
                this.profile = profile;
                this.apiService.getRep(this.profile.userPrincipalName).subscribe(data=>{
                  console.log(data)
                  this.lista=data;
                });
              });
          });
        }
      }
    });
  }

  changeTo(item: Reporte) {
    console.log(item.url);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(item.url);
  }


}
