export const environment = {
    production: false,
    urlAPI: '/api/reporte?email=',
    title: 'Tablero WEB',
    clientId: '2d4973ed-566a-4328-8c6e-0844b47ea690',
    authority: 'https://login.microsoftonline.com/4c1a5a2d-3c1e-45e1-ac0a-6ada80fb2ff9',
    redirectUri: 'https://tem.emprosoft.com.ar',		
 };

// urlAPI: 'http://localhost:3001?email=',

//  clientId: '2d4973ed-566a-4328-8c6e-0844b47ea690',
//  authority: 'https://login.microsoftonline.com/4c1a5a2d-3c1e-45e1-ac0a-6ada80fb2ff9',
