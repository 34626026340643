import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Reporte } from './entity/reporte';
import { Observable } from 'rxjs';
import { environment } from './config/app-config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  readonly APIUrl = environment.urlAPI;
  res: any

//   add_header 'Access-Control-Allow-Origin' '*';
// add_header 'Access-Control-Allow-Methods' 'GET, POST, OPTIONS, DELETE';
// add_header 'Access-Control-Allow-Headers' 'Accept,Accept-Language,Content-Language,Content-Type';
// add_header 'Access-Control-Expose-Headers' 'Content-Length,Content-Range';
  constructor(private http:HttpClient) { }

  getReports(email) {
    console.log(this.APIUrl + email)
    return this.http.get<Reporte[]>(this.APIUrl + email);
  }

  getRep(email):Observable<any>{
    console.log(email);
    let header = new HttpHeaders();
    header.set('Access-Control-Allow-Origin', '*');
    return this.http.get<any>(this.APIUrl + email, { headers: header });
  }
}
